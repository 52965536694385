<template>
  <div class="mt-10 xl:mx-56 lg:mx-28 sm:mx-10 mx-4">
    <div class ="flex justify-between align-middle bg-white rounded-t px-8 pt-8 dark:bg-primaryDark">
      <div class="flex flex-row dark:text-white pl-2">
        <div class="flex items-center justify-center">
          <font-awesome-icon icon="fa-solid fa-users" size="lg"/>
        </div>
        <h2 class="m-2 text-xl">Companies</h2>
      </div>
     <router-link :to="{ name: 'AddCompany'}" v-show="isCreator">
      <AddButton title="Add Company">
        <font-awesome-icon icon="fa-solid fa-plus"/>
      </AddButton>
    </router-link>
    </div>
    <div class="">
     <DynamicTable :filters="companyFilters" :link="profileViewLink" @sort="onSortParamEntry" @next="onNextPage" @prev="onPreviousPage" :searchFunc="onSearchParamEntry" :update="update" />
    </div>
  </div>
</template>

<script>
import {useStore} from "vuex";
import { computed } from "vue";
import { useRouter } from "vue-router";
import DynamicTable from "@/components/widgets/dynamicTable/DynamicTable";
import AddButton from "../components/inputs/AddButton";
export default {
  name: "companies",
  components: { DynamicTable, AddButton },
  setup() {
    const store = useStore()
    const router = useRouter()

    store.dispatch('dynamicTableLoadCompanies')

    function onCompanyView(id){
      router.push({ name: 'Company', params: { id }})
    }

    return {
      onCompanyView,
      store,
      companyFilters: computed(() => store.getters.getCompanyFilters),
      profileViewLink: computed(() => {
        return {func: onCompanyView, fieldName: 'Name'}
      }),
      async onSearchParamEntry(searchString) {
        store.commit('setDynamicTableSearchParam', searchString)
        await store.dispatch('dynamicTableLoadCompanies')
      },
      async update(){
        await store.dispatch('dynamicTableLoadCompanies')
      },
      async onSortParamEntry(key) {
        if (key === 'Name'){
          store.commit('setCompaniesSortBy', 'name')
        } else if (key === 'Type') {
          store.commit('setCompaniesSortBy', 'type')
        } else if (key === 'Country') {
          store.commit('setCompaniesSortBy', 'country')
        }
        await store.dispatch('dynamicTableLoadCompanies')
      },
      isCreator: computed(
        () => store.getters.getId 
      ),
      onNextPage: () => store.dispatch('companiesPageNext'),
      onPreviousPage: () => store.dispatch('companiesPagePrevious')
    }
  }
}
</script>
